.container {
    height: 500;
    width: 500;
    z-index: 9999;
}

.marker {
    /* background-color: red; */
    /* height: 200;
    width: 200; */
}

.mapboxgl-canvas {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

.pop-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}