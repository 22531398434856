.pdl-200 {
  padding-top: 30px;
  padding-left: 200px;
}
.pdt-150 {
  padding-top: 150px;
  padding-left: 10px;
}
.pd-80 {
  padding-top: 80px;
}
.wi-400 {
  padding: 7px 30px;
  width: 400px;
  position: relative;
}

.text {
  color: black;
  font-size: bold;
  font-size: 30px;
  font-weight: bold;
  padding-top: 20px;
}
.content {
  font-size: 15px;
  padding-top: "20px";
}
.bl-5px {
  border-left: 5px solid orangered;
  padding: 25px 25px 10px 30px;
  font-size: x-large;
  font-weight: bold;
}
.bd-10 {
  padding: 25px 0px 25px;
  box-sizing: content-box;
  box-shadow: 0 6px 10px 0 grey;
  width: 400px;
  background-color: #ffff;
  border-radius: 19px;
  min-height: 260px;
}

.button {
  padding-left: 25px;
  padding-top: 35px;
}
