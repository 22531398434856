.trip-head {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    padding: 24px;
}

.trip-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    padding: 0px 24px 16px 24px;
    color: #000000;
}

.route-text {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: black;
}

.gps-icon {
    height: 35px;
    width: 40px;
    cursor: pointer;
}

.detail-head {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
}

.triplist {
    height: 70vh;
    overflow-y: scroll;
    width: 100%;
    overflow-x: hidden;
}

.triplist::-webkit-scrollbar {
    overflow-y: hidden;
    width: 0px;
}

/* route list */
.bar {
    list-style: none;
    height: 65vh;
    margin-left: -30px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.bar::-webkit-scrollbar {
    overflow-y: hidden;
    width: 0px;
}

.bar>li {
    position: relative;
}

.bar>li:before {
    content: '\25CF';
    margin-right: 10px;
    font-size: 20px;
    color: red;
}

.bar>li:after {
    position: absolute;
    left: 0;
    top: 20px;
    content: '';
    border-left: 2px solid black;
    margin-left: 5px;
    height: 100%;
}

.bar>li:first-of-type:after {
    top: 40%;
}

.bar>li:last-of-type:after {
    top: -60%;
}

.route-item {
    position: absolute;
    margin-left: 20px;
    margin-top: 5px;
    white-space: nowrap;
}

.time {
    margin: 4px 20px;
}

li.visit-green:before {
    color: #54ff54 !important;
}

li.visit-red:before {
    color: red !important;
}

li.start-point:before,
li.end-point:before,
li.base-pointer:before {
    color: #000000;
}

/* loader */

.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    margin: 50% 25%;
}

.map-spinner {
    margin: 30% 40% !important;
}

.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: black;
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}